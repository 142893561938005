import React, { useState, useEffect, useRef } from "react";
import config from "../../../Config";
import axios from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { scrollToTop } from '../Shared/ScrollOnTop';
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { crypto } from "../../Common/crypto";
import JazzCashImage from '../../../assets/images/jazzcash.png';
import JazzShopImage from '../../../assets/images/jazzshop.png'
import mpin from '../../../assets/images/mpin.png'
import CreditCardImage from '../../../assets/images/credit-card.png'
import hit1 from '../../../assets/images/hit-1.png'
import hit2 from '../../../assets/images/hit-2.png'
import male from '../../../assets/images/male.svg'
import female from '../../../assets/images/female.svg'
import { generateRandomString } from "../General/Utility";
import { Form, Modal, Spinner } from "react-bootstrap";
import Logo from "../../../assets/images/logo/dps-logo.png";
import html2canvas from "html2canvas";
const PrintVouchers = () => {






  useEffect(() => {
    AOS.init();
  }, []);

  const [IpAddress, setIpAddress] = useState('');
  const [loadinginvoice, setloadinginvoice] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responce = await fetch('https://api.ipify.org');
        const data = await responce.text();
        setIpAddress(data);
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  const [show, setShow] = useState(false);
  const [errorshow, seterrorShow] = useState(false);
  const [JazzMassage, setJazzMassage] = useState('');

  const handleClose = () => setShow(false);

  const handleinvoiceClose = () => {
    setInvoiceShow(false);
    navigate('/');
  };



  const MethodOnePercentage = 1.16;

  const [validated, setValidated] = useState(false);

  const params = useParams();

  const [Showcontent, setShowcontent] = useState(false);
  const [studentInfo, setStudentInfo] = useState({});
  const [loading, setloading] = useState(false);
  const [RegistrationNo, SetRegistrationNo] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [Usercaptcha, setUsercaptcha] = useState("");
  const [Commission, setCommission] = useState("");
  const [Fee, setFee] = useState("");
  const [FinalFee, setFinalFee] = useState("");
  const [Method, SetMethod] = useState("");
  const [MobileNO, SetMobileNO] = useState("");
  const [CnicNo, SetCnicNo] = useState("");
  const [JazzData, SetJazzData] = useState({});
  const [InvoiceShow, setInvoiceShow] = useState(false);
  const [CurrentDate, setCurrentDate] = useState('');

  const navigate = useNavigate();
  const handleClick = () => {

    setloading(true);
    setShowcontent(false);
    const data = {
      Registration_No: RegistrationNo,
    };

    var api_config = {
      method: 'post',
      url: config.base_url + 'Student/GetStudentByRegistrationNoWK',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setStudentInfo(response.data.Student);
          setFee(response.data.Student.Current_Balance);
          setShowcontent(true);
          setloading(false);
        }
        else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });

        }
      })
      .catch(function (error) {
      });

  };

  useEffect(() => {
    document.title = "Print Vouchers";
    if (params.id) {
      setloading(true);
      setShowcontent(false);
      const data = {
        Registration_No: params.id,
      };

      var api_config = {
        method: 'post',
        url: config.base_url + 'Student/GetStudentByRegistrationNoWK',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };


      axios(api_config)
        .then(function (response) {
          if (response.data.status_code == 1) {
            setStudentInfo(response.data.Student);
            setFee(response.data.Student.Current_Balance);
            setShowcontent(true);
            SetRegistrationNo(params.id);
            setloading(false);
          }
          else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });
            setloading(false);
          }
        })
        .catch(function (error) {
        });
    }
  }, [params.id])


  const handlePayNow = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    // Mobile number and CNIC validation patterns
  const mobileNoPattern = /^0\d{10}$/;  // Must start with 0 and have 11 digits in total
  const cnicPattern = /^\d{6}$/;        // Must be exactly 6 digits

  // Validation for Mobile Number
  if (!mobileNoPattern.test(MobileNO)) {
    toast.error('Mobile number must start with 0 and be exactly 11 digits.', {
      position: toast.POSITION.TOP_RIGHT
    });
    return;
  }

  // Validation for CNIC Number
  if (!cnicPattern.test(CnicNo)) {
    toast.error('CNIC number must be exactly 6 digits.', {
      position: toast.POSITION.TOP_RIGHT
    });
    return;
  }

  
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      let host = window.location.hostname;

      if (host !== 'localhost' && Usercaptcha !== captcha) {
        toast.error('Incorrect Captcha', {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }


      const data = {
        Registration_No: RegistrationNo,
        Fee_Amount: Fee,
        Fee_Commission: Commission,
        Payment_Method: Method,
      };
      try {
        const feeOrderResponse = await axios.post(config.base_url + 'FeeOrder/AddFeeOrder', data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        //console.log('feeOrderResponse', feeOrderResponse);
        if (feeOrderResponse.data.status_code === 1) {

          setShow(true);

          const currentDateTime = moment();
          //const pp_TxnDateTime = currentDateTime.format('YYYYMMDDHHmmss');
          const pp_TxnDateTime = feeOrderResponse.data.ServerPkDateTime;
          const expiryDateTime = currentDateTime.add(2, 'day');
          const TxnExpiryDateTime = expiryDateTime.format('YYYYMMDDHHmmss');

          const walletData = {
            pp_Amount: FinalFee.replace(".", ""),
            pp_BillReference: RegistrationNo,
            pp_CNIC: CnicNo,
            pp_Description: "Student Fee",
            pp_Language: "EN",
            pp_MerchantID: config.MerchantID,
            pp_MobileNumber: MobileNO,
            pp_Password: config.Password,
            pp_TxnCurrency: "PKR",
            pp_TxnDateTime: pp_TxnDateTime,
            pp_TxnExpiryDateTime: TxnExpiryDateTime,
            pp_TxnRefNo: feeOrderResponse.data.OrderID,
            ppmpf_1: Fee,
          };

          walletData.pp_SecureHash = crypto(walletData);

          const walletResponse = await axios.post(config.base_url + 'FeeOrder/SendOrdertoJazz', walletData, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          //console.log(walletResponse.data.jazzCashResponse,'consle-0');
          SetJazzData(walletResponse.data.jazzCashResponse);
          if (walletResponse.data.jazzCashResponse.pp_ResponseCode === "000") {
            toast.success(walletResponse.data.jazzCashResponse.pp_ResponseMessage, {
              position: toast.POSITION.TOP_RIGHT
            });
            setInvoiceShow(true);

          }
          else if (walletResponse.data.jazzCashResponse.pp_ResponseCode === "157") {
            toast.success(walletResponse.data.jazzCashResponse.pp_ResponseMessage, {
              position: toast.POSITION.TOP_RIGHT
            });
            setTimeout(() => {
              navigate('/');
            }, 3000)
          } else {
            setShow(false);
            seterrorShow(true);
            setJazzMassage(walletResponse.data.jazzCashResponse.pp_ResponseMessage);
          }

          const updateData = {
            ID: walletResponse.data.jazzCashResponse.pp_TxnRefNo,
            Response_Code: walletResponse.data.jazzCashResponse.pp_ResponseCode,
            Response_Details: walletResponse.data.jazzCashResponse.pp_ResponseMessage,
            MobileNumber: walletResponse.data.jazzCashResponse.pp_MobileNumber,
            CNIC: walletResponse.data.jazzCashResponse.pp_CNIC,
            Fee_Amount: walletResponse.data.jazzCashResponse.pp_Amount,
            IP_Address: IpAddress,
          };

          const updateResponse = await axios.post(config.base_url + 'FeeOrder/UpdateFeeOrder', updateData, {
            headers: {
              'Content-Type': 'application/json'
            }
          });

          if (updateResponse.data.status_code === 1) {
            toast.success(updateResponse.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });
            setShow(false);
            SetMobileNO('');
            SetCnicNo('');
            SetRegistrationNo('');
          } else {
            toast.error(updateResponse.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });
            setShow(false);
            SetMobileNO('');
            SetCnicNo('');
            SetRegistrationNo('');
          }
        } else {
          toast.error(feeOrderResponse.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });
          SetMobileNO('');
          SetCnicNo('');
          SetRegistrationNo('');
        }
      } catch (error) {

      }
    }
    setValidated(true);
  };


  function calculatePercentage(value, percent) {
    return (Math.round((value + Number.EPSILON) * percent) / 100).toFixed(2);
  }

  const calculateFinalFee = (fee, percentageDecided) => {
    const percentageDeduction = percentageDecided / 100;
    const finalFee = fee / (1 - percentageDeduction);
    return finalFee.toFixed(2); // Round to two decimal places
  };

  useEffect(() => {
    const date = moment().format('DD');
    setCurrentDate(Number(date));
    setCaptcha(generateRandomString(6));
  }, []);

  const UpdateCaptcha = () => {
    setCaptcha(generateRandomString(6));
  }

  const currentBalance = parseFloat(studentInfo.Current_Balance);
  const MethodOneFinalFee = calculateFinalFee(currentBalance, 1.16);
  const MethodOneserviceFee = MethodOneFinalFee - currentBalance; 

  const InvoiceRef = useRef();

  const handleCapture = () => {
    if (InvoiceRef.current !== null) {
      html2canvas(InvoiceRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg");

        // Create a link element
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "Transactions Invoice.jpg";

        // Append the link to the body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Remove the link from the body
        document.body.removeChild(link);
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <Modal centered show={InvoiceShow} onHide={handleinvoiceClose} className="modal-theme transparent">
        <Modal.Header closeButton>
          <Modal.Title>Payment Receipt</Modal.Title>
          <button className="btn-close" onClick={handleinvoiceClose}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
              <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
            </svg>

          </button>
        </Modal.Header>
        <Modal.Body>

          {
            loadinginvoice ?
              <Spinner animation="border" size="sm" className='full-screen-loader' />
              :
              <div className="invoice-page" ref={InvoiceRef}>
                <div className="subpage">
                  <div className="invoice-top">
                    <img src={Logo} />
                    <div className="title">Payment Receipt</div>
                  </div>
                  <div className="invoice-content">
                    <ul>
                      <li>
                        <label>Student Name</label>
                        <span>{studentInfo.Student_Name}</span>
                      </li>
                      <li>
                        <label>Father Name</label>
                        <span>{studentInfo.Father_Name}</span>
                      </li>
                      <li>
                        <label>Registration No.</label>
                        <span>{studentInfo.Registration_No}</span>
                      </li>
                      <li>
                        <label>Mobile Number</label>
                        <span>{JazzData.pp_MobileNumber}</span>
                      </li>
                      <li>
                        <label>CNIC</label>
                        <span>{JazzData.pp_CNIC}</span>
                      </li>
                      <li>
                        <label>Amount Paid Rs.</label>
                        <span>{JazzData.pp_Amount / 100}</span>
                      </li>
                      <li>
                        <label>Wing</label>
                        <span> {studentInfo.SelectedClass?.SelectedWing?.Wing_Name}</span>
                      </li>
                      <li>
                        <label>Class / Section</label>
                        <span> {studentInfo.SelectedClass?.Class_Name} / {studentInfo.SelectedSection?.Section_Name}</span>
                      </li>
                      <li>
                        <label>Transaction Reference</label>
                        <span> {JazzData.pp_RetreivalReferenceNo} </span>
                      </li>
                      <li>
                        <label>Date</label>
                        <span>{moment().format('DD/MM/YYYY - HH:mma')}</span>
                      </li>
                      <li >
                        <label className="w-100">{JazzData.pp_ResponseMessage}</label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
          }

        </Modal.Body>
        <Modal.Footer>
          <div className="button-row w-100 justify-content-between d-flex">
            <button className='button button-primary' style={{ width: '48%' }} onClick={handleCapture}>
              Save to Gallery
            </button>
            <button className='button button-outline-primary' style={{ width: '48%' }} onClick={() => navigate('/')}>
              Go to Home
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Body className="text-center">
          <img src={mpin} className="img-fluid" alt="mpin" />
          <p className="mt-3 fw-bold">Please enter your MPIN on your mobile phone.</p>
          <div className={`task w-100`}>
            <div className="progress" role="progressbar" aria-label="Default striped example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
              <div className="progress-bar progress-bar-striped" style={{ width: '100%' }}></div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={errorshow}
        backdrop="static"
        centered
        keyboard={false}
      // onHide={() => window.location.reload()}
      >
        <Modal.Body className="text-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 w-25 text-danger">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
          </svg>
          <p className="mt-3">{JazzMassage}</p>
          <div className="button-row justify-content-center">
            <button className="button button-secondary" onClick={() => { seterrorShow(false); SetMobileNO(''); SetCnicNo(''); setUsercaptcha('') }}>
              Retry again
            </button>
            <button className="button button-outline-secondary" onClick={() => navigate(`/`)}>
              Back to Home
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <div className="page-head">
        <div className="container">
          <h3>Print Vouchers / Online Fee</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link onClick={scrollToTop} to="/Home">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Print Vouchers / Online Fee
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <section className="simple-section">
        <div className="container">
          <div className="registration-card">
            <div className="row align-items-center gy-3">
              <div className="col-md-5">
                <h3>Print Vouchers</h3>
                <p>Please enter the registration number.</p>
                <em className="text-danger">Last date for Fee deposit is 25th of every month</em>
              </div>
              <div className="col-md-7">
                <div className="result-card">
                  <div className="input-group">
                    <input type="text" placeholder="Enter Registration No" value={RegistrationNo} onChange={(e) => SetRegistrationNo(e.target.value)} className="form-control" />
                  </div>
                  <div className="button-row mt-3 justify-content-end">
                    <Link onClick={scrollToTop} to={`/PrintVouchers/PrintChallan/?Registration_No=${RegistrationNo}`}
                      target="_blank" className="button button-secondary"
                    // style={{
                    //   display: "inline-flex",
                    //   alignItems: "center",
                    //   opacity: 0.5, // Reduce opacity for disabled appearance
                    //   cursor: 'not-allowed', // Change cursor to 'not-allowed'
                    //   pointerEvents: 'none', // Disable pointer events
                    // }}
                    >Print Vouchers</Link>
                    <button
                      className="button button-secondary"
                      disabled={CurrentDate > 25 || CurrentDate < 5} // Disable button if date > 25 or < 5
                      onClick={handleClick}
                    >
                      Pay Online {loading ? '...' : ''}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {loading ?
            <div className="d-flex justify-content-center my-5">
              <Spinner></Spinner>
            </div>
            :
            ''}

          {
            Showcontent &&
            <>

              <div className="row gy-3 align-items-center">
                <div className="col-md-12">
                  <div className="student-card mt-3">
                    <div className="card-header">
                      <div className="profile">
                        <img src={studentInfo.Gender ? male : female} alt="profile" />
                      </div>
                      <div className="user-name">
                        <h1>{studentInfo.Student_Name}</h1>
                        <p>{studentInfo.Father_Name}</p>
                      </div>

                    </div>
                    <div className="card-body">
                      <div className="menu">
                        <div className="outline">
                          <span>Wing</span><b> {studentInfo.SelectedClass?.SelectedWing?.Wing_Name}</b>
                        </div>
                        <div className="outline">
                          <span>Class</span><b> {studentInfo.SelectedClass?.Class_Name}</b>
                        </div>
                        <div className="outline">
                          <span>Section</span><b> {studentInfo.SelectedSection?.Section_Name}</b>
                        </div>
                        <div className="outline">
                          <span>Registration No</span><b> {studentInfo.Registration_No}</b>
                        </div>
                        <div className="outline">
                          <span>Status</span><b>
                            {
                              studentInfo.Status_Type_Id === 1 ? "Normal" :
                                studentInfo.Status_Type_Id === 2 ? "Stucked Off" :
                                  studentInfo.Status_Type_Id === 3 ? "Left" :
                                    studentInfo.Status_Type_Id === 4 ? "Study Complete" :
                                      studentInfo.Status_Type_Id === 5 ? "SLC" :
                                        studentInfo.Status_Type_Id === 6 ? "Freezed" : "Not Enrolled"

                            }
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <img src={hit1} alt="help" className="img-fluid" />
                </div>
                <div className="col-md-6">
                  <img src={hit2} alt="help" className="img-fluid" />
                </div>
                <div className="col-md-12">
                  <div className="registration-card">
                    <div className="row align-items-center">
                      <div className="col-md-5">
                        <h3>Method 1.</h3>
                        <p>Pay your fee using your Mobilink Jazz cash account.</p>
                        <img src={JazzCashImage} width={150} alt="jazz cash" />
                      </div>
                      <div className="col-md-7">
                        <div className="result-card">
                          <ul className="fee-list flex-coloum  mb-4">
                            <li>
                              <label>Fee : {studentInfo.Current_Balance}/-</label>
                            </li>
                            <li>+</li>
                            <li>
                              <label>(Service Fee {MethodOnePercentage}% ): Rs.{(MethodOneserviceFee).toFixed(2)}/-</label>
                            </li>
                            <li>=</li>
                            <li>
                              <label>Total Amount. {MethodOneFinalFee}/-</label>
                            </li>
                          </ul>
                          <div className="text-start">
                            <Form noValidate validated={validated} onSubmit={handlePayNow}>
                              <input type="text" required placeholder="Jazz Cash Number" maxLength={11} value={MobileNO} onChange={(e) => SetMobileNO(e.target.value)} className="form-control" />
                              <input type="text" required placeholder="Last 6 Digit of CNIC Number" maxLength={6} value={CnicNo} onChange={(e) => SetCnicNo(e.target.value)} className="form-control mt-3" />
                              <p className="text-primary my-3">
                                <i>The CNIC should be the one registered with the provided Jazzcash Wallet/Account no, otherwise the transaction will fail.</i>
                              </p>
                              <div className="captcha-verify-row">
                                <div className="verify">
                                  <div className="Character" >
                                    {captcha}
                                  </div>
                                  <NavLink className='button button-primary bg-black' onClick={UpdateCaptcha}><i className="ri-refresh-line"></i></NavLink>
                                  <div className="form-group">
                                    <input type="text" onChange={(e) => setUsercaptcha(e.target.value)} className="form-control" value={Usercaptcha} placeholder="Enter Captcha" required />
                                  </div>
                                </div>
                                <button className="button button-secondary" type="submit"
                                  onClick={() => {
                                    SetMethod('MA');
                                    setCommission(MethodOneserviceFee);
                                    setFinalFee(MethodOneFinalFee);
                                  }}
                                >Pay Now</button>
                              </div>
                            </Form>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }

        </div>
      </section>
    </>
  );
};

export default PrintVouchers;
