import React, { useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import logo from '../../../assets/images/logo/Logo.png'
import dps_logo from '../../../assets/images/logo/dps-logo.png'
import home from '../../../assets/images/sidebar_icon/home.svg'
import exam from '../../../assets/images/sidebar_icon/exam.svg'
import fee from '../../../assets/images/sidebar_icon/fee.svg'
import home_work from '../../../assets/images/sidebar_icon/home_work.svg'
import voucher from '../../../assets/images/sidebar_icon/voucher.svg'
import school from '../../../assets/images/sidebar_icon/school.svg'
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import { scrollToTop } from '../Shared/ScrollOnTop';

function Header(props) {
    useEffect(() => {
        const handleScroll = () => {
            const header = document.querySelector('.sticky-header');
            if (window.scrollY > 300) {
                header.classList.add('scrolled');
            } else {
                header.classList.remove('scrolled');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };

    return (
        <>
            <div className="site-header">
                <div className="topbar">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <ul className="top-contact">
                                    <li>
                                        <i className="ri-phone-line"></i>
                                        0499250165
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="top-social">
                                    <li className="about-us">
                                        <NavLink onClick={scrollToTop} target='_blank' to=""> About Us </NavLink>
                                    </li>
                                    <li className="contact-us">
                                        <NavLink onClick={scrollToTop} target='_blank' to=""> Contact Us </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={scrollToTop} target='_blank' to="#"> Follow us: </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={scrollToTop} target='_blank' to="#">
                                            <span>
                                                <i className="ri-facebook-fill"></i>
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={scrollToTop} target='_blank' to="">
                                            <span>
                                                <i className="ri-youtube-line"></i>
                                            </span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header className="sticky-header" >
                <nav className="navbar navbar-expand-lg bg-white">
                    <div className="container-fluid">
                        <NavLink onClick={scrollToTop} className="navbar-brand" to="/Home">
                            <img src={logo} className="logo" alt="" />
                            <img src={dps_logo} className="logo-1" alt="" />
                        </NavLink>
                        <div className="toggle-side-menu">
                            <NavLink onClick={handleShow} style={{ cursor: "pointer",color:"black"}}>
                                <i className="ri-menu-3-fill"></i>
                            </NavLink>
                        </div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav m-auto">
                                <li className="nav-item">
                                    <NavLink onClick={scrollToTop} className="nav-link active" aria-current="page" to="/Home">Home</NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <NavLink className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" to=" #">The School</NavLink>
                                    <ul className="dropdown-menu">
                                        <li><NavLink onClick={scrollToTop} className="dropdown-item" to="/Home/Introduction/">Introduction</NavLink></li>
                                        <li><NavLink onClick={scrollToTop} className="dropdown-item" to="/Home/Administration">Administration</NavLink></li>
                                        <li>
                                            <NavLink onClick={scrollToTop} className="dropdown-item" to="/Home/BoardOfGovernors">Board of Governors</NavLink>
                                        </li>
                                        <li><NavLink onClick={scrollToTop} className="dropdown-item" to="/Home/Jobs">Jobs</NavLink></li>
                                        <li><NavLink onClick={scrollToTop} className="dropdown-item" to="/Home/Faculty">Faculty</NavLink></li>
                                        <li>
                                            <NavLink onClick={scrollToTop} className="dropdown-item" to="/Home/PromotionPolicy">Promotion Policy</NavLink>
                                        </li>
                                        <li><NavLink onClick={scrollToTop} className="dropdown-item" to="/Home/SchoolUniform">School Uniform</NavLink></li>
                                        <li><NavLink onClick={scrollToTop} className="dropdown-item" to="/Home/Syllabus">Syllabus</NavLink></li>
                                        <li></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <NavLink onClick={scrollToTop} className="nav-link" to="/Home/FeeStructure">Fee Structure</NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <NavLink  className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" to=" #">Examinations</NavLink>
                                    <ul className="dropdown-menu">
                                        <li><NavLink onClick={scrollToTop} className="dropdown-item" to="/Examination/DateSheet">Date Sheet</NavLink></li>
                                        <li>
                                            <NavLink onClick={scrollToTop} className="dropdown-item" to="/Examination/Results">Result Annual Term</NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <NavLink onClick={scrollToTop} className="nav-link" to="/Home/HomeWork">Home Work</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink onClick={scrollToTop} className="nav-link" to="/Home/PrintVouchers">Fee Voucher</NavLink>
                                </li>
                            </ul>
                            <form className="d-flex ng-pristine ng-valid" role="search">
                                <NavLink onClick={scrollToTop} className="nav-link button button-outline-secondary" to="#">
                                    <span>
                                        <i className="ri-user-6-line"></i>
                                    </span>
                                    Student/Parent Login
                                </NavLink>
                                <NavLink onClick={scrollToTop} className="nav-link button button-outline-secondary ms-3" to="">
                                    <span>
                                        <i className="ri-login-box-line"></i>
                                    </span>
                                    Staff Login
                                </NavLink>
                            </form>
                        </div>
                    </div>

                    <Offcanvas show={show} style={{ width: "300px" }} onHide={handleClose} placement='end'>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Menu</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className='scrollbar'>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header><img src={home} alt="" /> Home</Accordion.Header>
                                    <Accordion.Body>
                                        <ul className="sub-menu">
                                            <li><NavLink onClick={scrollToTop} to="/Home">Home</NavLink></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><img src={school} alt="" />The School</Accordion.Header>
                                    <Accordion.Body>
                                        <ul className="sub-menu">
                                            <li><NavLink onClick={scrollToTop} className="" to="/Home/Introduction/">Introduction</NavLink></li>
                                            <li><NavLink onClick={scrollToTop} className="" to="/Home/Administration">Administration</NavLink></li>
                                            <li><NavLink onClick={scrollToTop} className="" to="/Home/BoardOfGovernors">Board of Governors</NavLink></li>
                                            <li><NavLink onClick={scrollToTop} className="" to="/Home/Jobs">Jobs</NavLink></li>
                                            <li><NavLink onClick={scrollToTop} className="" to="/Home/Faculty">Faculty</NavLink></li>
                                            <li><NavLink onClick={scrollToTop} className="" to="/Home/PromotionPolicy">Promotion Policy</NavLink></li>
                                            <li><NavLink onClick={scrollToTop} className="" to="/Home/SchoolUniform">School Uniform</NavLink></li>
                                            <li><NavLink onClick={scrollToTop} className="" to="/Home/Syllabus">Syllabus</NavLink></li>

                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><img src={fee} alt="" />Fee Structure</Accordion.Header>
                                    <Accordion.Body>
                                        <ul className="sub-menu">
                                            <li><NavLink onClick={scrollToTop} to="/Home/FeeStructure">Fee Structure</NavLink></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><img src={exam} alt="" />Examinations</Accordion.Header>
                                    <Accordion.Body>
                                        <ul className="sub-menu">
                                            <li><NavLink onClick={scrollToTop} className="" to="/Examination/DateSheet">Date Sheet</NavLink></li>
                                            <li>
                                                <NavLink onClick={scrollToTop} className="" to="/Examination/Results">Result Annual Term</NavLink>
                                            </li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header><img src={home_work} alt="" />Home Work</Accordion.Header>
                                    <Accordion.Body>
                                        <ul className="sub-menu">
                                            <li><NavLink onClick={scrollToTop} to="/Home/HomeWork">Home Work</NavLink></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header><img src={voucher} alt="" /> <NavLink onClick={scrollToTop} className="nav-link" to="/Home/PrintVouchers">Fee Voucher</NavLink></Accordion.Header>
                                    <Accordion.Body>
                                        <ul className="sub-menu">
                                            <li>
                                                <NavLink onClick={scrollToTop} className="nav-link" to="/Home/PrintVouchers">Fee Voucher</NavLink>
                                            </li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Offcanvas.Body>
                    </Offcanvas>
                </nav>
            </header>
        </>
    );
}

export default Header;