import React, { useState, useEffect } from "react";
import books from '../../../assets/images/Library/books.JPG'
import config from "../../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Dropdown } from "react-bootstrap";
import Pagination from "../../General/Pagination";
import { RenderHTMLString } from "../../General/Utility";
import { scrollToTop } from '../Shared/ScrollOnTop';

const DateSheet = () => {

    document.title = "DateSheet";

    const [AccessKey, setAccessKey] = useState("239f5be8-5389-44ea-8935-b5fe638339da");
    const [UserID, setUserID] = useState("1");
    const [MemberType, setMemberType] = useState("admin");

    const [designationID, setDesignationId] = useState(0);
    const [empTypeID, seEmployeeTypeId] = useState(0);
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [showFilters, setShowFilters] = useState(true);

    // pagination 
    const [pageno, setPageNo] = useState(1);
    const [pagesize, setPageSize] = useState(10);
    const [totalrecords, setTotalRecords] = useState(0);

    const [bookName, setBookName] = useState("");
    const [writerName, setWriterName] = useState("");
    const [publisherName, setPublisherName] = useState("");
    const [bookNo, setBookNo] = useState("");
    const [status, setStatus] = useState("");
    const [orderBy, setOrderBy] = useState("");
    const [ContentPage, setContentPage] = useState({});

    useEffect(() => {

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: "Admin",
            ID: "4"

        };
        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'ContentPage/GetContentPageByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setContentPage(response.data.ContentPage);

                }


                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, []);


    return (
        <>
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <h3>DATE SHEET</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/Home">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">DATE SHEET</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="simple-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h2>
                                <b>
                                    {ContentPage.Page_Title}
                                </b>
                            </h2>
                            <div className="card mt-4 mb-4">
                                <div className="card-body">
                                    <table className="table table-theme table-condensed table-striped table-hover mb-md-0">
                                        <thead>
                                            <tr>
                                                <th>DATE</th>
                                                <th>DAY</th>
                                                <th>P.G.</th>
                                                <th>NUR.</th>
                                                <th>PREP</th>
                                                <th>I</th>
                                                <th>II</th>
                                                <th>III</th>
                                                <th>IV</th>
                                                <th>V</th>
                                                <th>VI</th>
                                                <th>VII</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>26-02-24</td>
                                                <td>Mon</td>
                                                <td>Eng(R)</td>
                                                <td>Eng(R)</td>
                                                <td>Eng(R)</td>
                                                <td>ناظرہ قرآن</td>
                                                <td>ناظرہ قرآن</td>
                                                <td>ناظرہ قرآن</td>
                                                <td>ناظرہ قرآن</td>
                                                <td>-----</td>
                                                <td>Urdu</td>
                                                <td>Math</td>
                                            </tr>
                                            <tr>
                                                <td>27-02-24</td>
                                                <td>Tue</td>
                                                <td>Urdu(R)</td>
                                                <td>Urdu(R)</td>
                                                <td>Urdu(R)</td>
                                                <td>Eng(O)</td>
                                                <td>Eng(O)</td>
                                                <td>Eng(O)</td>
                                                <td>Eng(O)</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                            </tr>
                                            <tr>
                                                <td>28-02-24</td>
                                                <td>Wed</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>Isl (O)</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>Urdu(O)</td>
                                                <td>Urdu(O)</td>
                                                <td>Eng</td>
                                                <td>Isl</td>
                                                <td>Comp</td>
                                            </tr>
                                            <tr>
                                                <td>29-02-24</td>
                                                <td>Thu</td>
                                                <td>Eng</td>
                                                <td>Eng</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>S.St.</td>
                                                <td>S.St.</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                            </tr>
                                            <tr>
                                                <td>01-03-24</td>
                                                <td>Fri</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>Eng</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>Isl</td>
                                                <td>Isl</td>
                                                <td>G.Sci.</td>
                                                <td>Math</td>
                                                <td>S.St.</td>
                                            </tr>
                                            <tr>
                                                <td>04-03-24</td>
                                                <td>Mon</td>
                                                <td>Urdu</td>
                                                <td>Urdu</td>
                                                <td>-----</td>
                                                <td>G.Sci.</td>
                                                <td>G.Sci.</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>Isl</td>
                                                <td>G.Sci.</td>
                                                <td>Urdu</td>

                                            </tr>
                                            <tr>
                                                <td>05-03-24</td>
                                                <td>Tue</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>Urdu</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>G.Sci.</td>
                                                <td>G.Sci.</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                            </tr>
                                            <tr>
                                                <td>06-03-24</td>
                                                <td>Wed</td>
                                                <td>Math</td>
                                                <td>Math</td>
                                                <td>-----</td>
                                                <td>Eng</td>
                                                <td>Eng</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>S.St.</td>
                                                <td>Eng</td>
                                                <td>ترجمۃالقرآن</td>
                                            </tr>
                                            <tr>
                                                <td>07-03-24</td>
                                                <td>Thu</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>Urdu</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>G.Sci.</td>
                                                <td>G.Sci.</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                            </tr>
                                            <tr>
                                                <td>08-03-24</td>
                                                <td>Fri</td>
                                                <td>Isl(O)</td>
                                                <td>Isl(O)</td>
                                                <td>-----</td>
                                                <td>Urdu+ Urdu(O)</td>
                                                <td>Urdu+ Urdu(O)</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>Math</td>
                                                <td>S.St.</td>
                                                <td>Isl</td>
                                            </tr>
                                            <tr>
                                                <td>11-03-24</td>
                                                <td>Mon</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>Isl</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>Eng</td>
                                                <td>Eng</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                            </tr>
                                            <tr>
                                                <td>12-03-24</td>
                                                <td>Tue</td>
                                                <td>G.K.(O)</td>
                                                <td>G.K.(O)</td>
                                                <td>-----</td>
                                                <td>Math</td>
                                                <td>Math</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>Urdu</td>
                                                <td>Comp</td>
                                                <td>G.Sci.</td>
                                            </tr>
                                            <tr>
                                                <td>13-03-24</td>
                                                <td>Wed</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>Isl</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>Math</td>
                                                <td>Math</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                            </tr>
                                            <tr>
                                                <td>14-03-24</td>
                                                <td>Thu</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>Isl</td>
                                                <td>Isl</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>ترجمۃالقرآن</td>
                                                <td>Eng</td>
                                            </tr>
                                            <tr>
                                                <td>15-03-24</td>
                                                <td>Fri</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                                <td>S.St.</td>
                                                <td>S.St.</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                            </tr>
                                            <tr>
                                                <th colspan="13" className="text-center mb-md-0 pt-3">
                                                “O” stands for Oral & “R” for Reading
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <p>
                                {RenderHTMLString(ContentPage.Page_Detail)}
                            </p>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default DateSheet